<template>
  <div class="">
    <trac-loading v-if="isLoading" />
    <main class="ml-12" v-if="stockLogs">
      <div class="wrapper">
        <div class="flex flex-row justify-between">
          <ul class="flex-col mt-12 mb-24">
            <li><a href="#">Inventory Control</a></li>
            <li class="font-bold mt-4 text-2xl">
              Stock Adjustment
            </li>
          </ul>
          <ul class=" flex flex-row mt-32 mb-8 items-end">
            <li class="mr-5">
              <trac-button
                @button-clicked="selectAdjustmentType('add')"
                class="uppercase"
                variant="outline"
                >Add Stock</trac-button
              >
            </li>
            <li>
              <trac-button
                @button-clicked="selectAdjustmentType('remove')"
                class="uppercase"
                variant="outline"
                >Remove Stock</trac-button
              >
            </li>
          </ul>
        </div>
        <div class=" bg-white h-full rounded-lg overflow-hidden big-shadow">
          <div class="mt-8 mb-5 mx-8 justify-between flex flex-row">
            <div class="flex flex-row items-center">
       
              <trac-dropdown
                class="mr-5 w-48"
                :title="reasons[0].name"
                :menuItems="reasons"
                @input="selectReason"
              ></trac-dropdown>
            </div>
          </div>
          <div class="mx-8 mb-8 border rounded-lg overflow-auto">
            <table class="w-full table-auto overflow-auto">
              <thead class="bg-blue-100">
                <tr class="">
                  <th class="text-left text-xs p-4 uppercase">
                    Date
                  </th>
                  <th class="text-left text-xs p-4 uppercase">
                    Product
                  </th>
                  <th class="text-left text-xs p-4 uppercase">
                    Stock Adjustment
                  </th>
                  <th class="text-left text-xs p-4 uppercase">
                    Reason
                  </th>
                  <!-- <th class="text-left text-xs p-4 uppercase">
                    Store
                  </th> -->
                </tr>
              </thead>
              <tbody v-if="filteredLogs.length > 0">
                <tr
                  :class="index % 2 === 0 ? '' : 'bg-gray-100'"
                  v-for="(log, index) in filteredLogs"
                  class=""
                  :key="index"
                >
                  <td class="text-xs p-4 capitalize">
                    <span class=" text-xs">{{
                      moment(log.date).format("DD/MM/YYYY")
                    }}</span>
                    <br />
                    <span class="text-xs text-primaryGray">{{
                      moment(log.date).format("hh:mm A")
                    }}</span>
                  </td>
                  <td class="text-sm font-bold p-4 capitalize">
                    <div class="flex flex-row items-center gap-5">
                      <div class="h-10 w-10 border rounded">
                        <p class="text-white h-full flex justify-center items-center bg-gray-700" v-if="log.product_image.length === 0">
                          {{ log.name.substring(0, 1) }}
                        </p>
                        <img v-else :src="log.product_image" />
                      </div>
                      <span class="text-xs">{{ log.name }}</span>
                    </div>
                  </td>
                  <td class="text-xs  p-4 capitalize flex items-center pl-4">
                    <span class="text-base">{{ log.current_quantity === 0 ? '' : log.current_quantity > 0 ? '+' : '-' }}</span>
                    {{ Math.abs(log.current_quantity) }}
                  </td>
                  <td class="text-xs  p-4 capitalize">
                    {{ log.reason }}
                  </td>
                  <!-- <td class="text-xs  p-4 capitalize">
                    {{ "Main Store" }}
                  </td> -->
                </tr>
              </tbody>
              <tbody v-else>
            <tr>
              <td class="text-xs font-medium p-4" colspan="5">
                <trac-center-data>
                    <div class="h-40 flex items-center text-lg text-gray-600">
                      There is no data available.
                    </div>
                  </trac-center-data>
              </td>
            </tr>
          </tbody>
            </table>
          </div>
        </div>
      </div>
      <trac-modal v-if="selectStoreModal" @close="selectStoreModal = false">
        <SelectStore :adjustmentType="adjustmentType" :stores="stores"></SelectStore>
      </trac-modal>
    </main>
  </div>
</template>

<script>
import SelectStore from "./SelectStore";
import moment from "moment";
import {
  DELETE_LOCAL_DB_DATA,
  SAVE_LOCAL_DB_DATA,
} from "../../../browser-db-config/localStorage";
import { eventBus } from "./../../../main";

export default {
  components: { SelectStore },
  data() {
    return {
      isLoading: false,
      selectStoreModal: false,
      moment,
      adjustmentType: '',
      stockLogs: null,
      filteredLogs: null,
      reasons: [],
    };
  },
  async created() {
    this.isLoading = true;
    DELETE_LOCAL_DB_DATA("products-to-adjust");
    DELETE_LOCAL_DB_DATA("stock-adjustment-store-detail");
    await this.fetchAllStores();
    await this.fetchAllStockLogs();
    this.isLoading = false;
  },
  methods: {
    selectAdjustmentType(type) {
      this.adjustmentType = type;
      this.selectStoreModal = true;
    },
    selectStore(store) {
      // (store);
    },
    selectReason(reason) {
      if (reason === "All Reasons") {
        this.filteredLogs = this.stockLogs;
      } else {
        this.filteredLogs = this.stockLogs.filter(
          (log) => log.reason === reason
        );
      }
    },
    async fetchAllStores() {
      await this.$store.dispatch("FETCH_ALL_STORES");
      const res = this.$store.getters["GET_ALL_STORES"];

      if (res.status) {
        this.stores = res.data || [];
      }
    },
    async fetchAllStockLogs() {
      await this.$store.dispatch("FETCH_STOCK_ADJUSTMENT_LOG");
      const res = this.$store.getters["GET_ALL_STOCK_LOGS"];

      if (res.status) {
        this.stockLogs = res.data || [];
        this.filteredLogs = this.stockLogs;
        //  Set reason
        const reasons = [
          "All Reasons",
          ...new Set(this.stockLogs.map((log) => log.reason)),
        ].filter((log) => log.length > 0);
        this.reasons = reasons.map((reason) => {
          return { name: reason, value: reason };
        });
      } else {
        // alert(res.message || res.error);
        eventBus.$emit('trac-alert', { message: res.message || res.error });
      }
    },
  },
};
</script>

<style></style>
